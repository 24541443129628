<template>
  <div id="app">

    <div class="container py-10 px-10 mx-0 min-w-full flex flex-col items-center">
      <button class="bg-purple-900 text-white hover:bg-blue-400 font-bold py-2 px-4 mt-3 rounded" @click="sendToDisposable">Launch Camera</button>
    </div>

    <div class="bg-cover bg-center h-screen w-screen" style="background-image: url('/background2.png'); display: none;">
        <div style="margin-top: 0;" class="mr-10 ml-10 w-auto">
          <div class="mt-10">
            <video ref="video" autoplay playsinline></video>
          </div>

          <button v-if="!startedProcess" @click="startCamera" type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-20 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">
            Lets Get Started
          </button>
          <button v-if="startedProcess" @click="capturePhoto" type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 mt-10 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
            Take Photo
          </button>

          <a v-if="photoLink" :href="photoLink" target="_blank">
            Download
          </a>

        </div>
    </div>
  </div>
  <canvas ref="canvas" style="display: none;"></canvas>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      startedProcess: false,
      videoStream: null,
      photo: null, // Stores the captured photo
      photoLink: null,
      isFrontCamera: true, // Track if the front camera is being used
      s3Config: {
        // bucketUrl: 'https://dp.requestcatcher.com/upload',
        bucketUrl: 'https://capture-event.s3.eu-west-2.amazonaws.com',
        // region: 'eu-west-2'
      }
    };
  },
  methods: {
    sendToDisposable() {
      window.location.replace('https://disposable.app/camera/15999/2cd6ac25d15edbced77');
      return;
    },
    stopCamera() {
      // Stop all video tracks
      if (this.videoStream) {
        let tracks = this.videoStream.getTracks();
        tracks.forEach(track => track.stop());
        this.videoStream = null;
      }
    },
    async startCamera() {
      try {
        const constraints = {
          video: {
            facingMode: 'environment'
          },
        };

        // Request access to the camera
        this.videoStream = await navigator.mediaDevices.getUserMedia(constraints);
        this.$refs.video.srcObject = this.videoStream;

        this.startedProcess = true;
      } catch (err) {
        console.error("Error accessing the camera: ", err);
      }
    },

    switchCamera() {
      this.stopCamera(); // Stop the current stream
      this.isFrontCamera = !this.isFrontCamera; // Toggle camera mode
      this.startCamera(); // Restart the camera with the new mode
    },
    generateRandomFilename() {
      const timestamp = Date.now().toString(36); // Convert timestamp to base36
      const randomString = Math.random().toString(36).substring(2, 10); // Generate a random string
      return `${timestamp}-${randomString}`; // Combine timestamp and random string
    },
    async uploadToS3() {
      if (!this.photo) return;

      // Convert the Base64 photo to a Blob
      const blob = this.dataURLToBlob(this.photo);

      // Generate a unique file name (e.g., use current timestamp)
      const fileName = `${this.generateRandomFilename()}-${Date.now()}.jpeg`;

      // S3 PUT URL (replace `your-s3-bucket-name` with your actual S3 bucket name)
      const uploadUrl = `${this.s3Config.bucketUrl}/${fileName}`;
      // alert(uploadUrl);

      try {
        // Upload using a simple HTTP PUT request
        const response = await axios.put(uploadUrl, blob, {
          headers: {
            'Content-Type': 'image/jpeg', // The file type
          },
        });
        console.log(response);

        if (response.status === 200) {
          console.log('Photo uploaded successfully:', uploadUrl);
          this.photoLink = uploadUrl;
          // alert('Photo uploaded successfully to S3!');
        } else {
          console.error('Upload failed:', response);
          // alert('Failed to upload photo to S3.' + JSON.stringify(response));
        }
      } catch (error) {
        console.error('Error uploading photo:', error);
        // alert('Error uploading photo to S3.' + JSON.stringify(error));
      }
    },
    capturePhoto() {
      const canvas = this.$refs.canvas;
      const video = this.$refs.video;

      // Set the canvas size to match the video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw the current frame from the video onto the canvas
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas image to a data URL and store it as the captured photo
      this.photo = canvas.toDataURL('image/png');
      this.uploadToS3();
    },
    dataURLToBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
    },
  },
};
</script>

<style>
#app {
  text-align: center;
}
video {
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}
button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
}
img {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  border: 2px solid #000;
}
</style>
